import { createTheme } from "@mui/material/styles";

const typography = {
  fontFamily: ["Poppins", "sans-serif"].join(","),
};

const shape = {
  borderRadius: 12,
}

export const darkTheme = () => {
  return createTheme({
    typography,
    shape,
    palette: {
      mode: 'dark',
      background: {
        default: '#1D1D22',
        paper: '#23262F',
      },
      primary: {
        main: '#3B82F6',
        dark: '#2B64D3',
        light: '#6BA6F9',
        contrastText: '#fff',
      },
      secondary: {
        main: '#9757D7',
        dark: '#763FB8',
        light: '#B87FE7',
        contrastText: '#fff',
      },
      success: {
        main: '#65C382',
        contrastText: '#fff',
      },
      text: {
        primary: '#F4F5F6',
        secondary: 'rgba(255,255,255,0.6)',
      },
    },
  })
}

export const lightTheme = () => {
  return createTheme({
    typography,
    shape,
    palette: {
      background: {
        default: 'rgb(249, 249, 249)',
        paper: '#fff',
      },
      primary: {
        main: '#3B82F6',
        dark: '#2B64D3',
        light: '#6BA6F9',
        contrastText: '#fff',
      },
      secondary: {
        main: '#9757D7',
        dark: '#763FB8',
        light: '#B87FE7',
        contrastText: '#fff',
      },
      success: {
        main: '#65C382',
        contrastText: '#fff',
      },
      text: {
        primary: '#23262F',
        secondary: 'rgba(0,0,0,0.6)',
      },
    },
  })
}
