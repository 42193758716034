import { Stack, IconButton, Tooltip } from '@mui/material'
import { Chain } from '@inkerlabs/sdk'
import { StoreContext } from '../page/Store'
import { useContext } from 'react'
import { Language } from '@mui/icons-material'
import { availableChains } from '@inkerlabs/react-sdk'

interface Props {
  chain: Chain
  contractAddress: string
}

const Socials = ({ chain, contractAddress }: Props) => {
  const { store } = useContext(StoreContext)
  const network = availableChains.find((c) => c.network === chain)

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      {store.website && (
        <Tooltip title="View Website">
          <IconButton target="_blank" href={store.website}>
            <Language color="secondary" />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="View on Etherscan">
        <IconButton
          target="_blank"
          href={`${network?.blockExplorers?.default.url}/address/${contractAddress}`}
        >
          <img src="/etherscan.svg" width={20} />
        </IconButton>
      </Tooltip>

      {store.twitter && (
        <Tooltip title="View on Twitter">
          <IconButton target="_blank" href={store.twitter}>
            <img src="/twitter.svg" width={20} />
          </IconButton>
        </Tooltip>
      )}
      {store.discord && (
        <Tooltip title="View on Discord">
          <IconButton target="_blank" href={store.discord}>
            <img src="/discord.svg" width={20} />
          </IconButton>
        </Tooltip>
      )}
      {store.instagram && (
        <Tooltip title="View on Instagram">
          <IconButton target="_blank" href={store.instagram}>
            <img src="/instagram.svg" width={20} />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  )
}

export default Socials
