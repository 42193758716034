import { Grid, Typography, Box, Link, Tooltip } from "@mui/material";
import { AssetMetadata } from "@inkerlabs/react-sdk";
import Badge from "@mui/material/Badge";
import RevealButton from "./RevealButton";
import FilePreview from "./FilePreview";

interface Props {
  collectionName: string;
  id: string;
  metadata?: AssetMetadata;
  quantity?: number;
  revealable?: boolean;
}

const NFT = ({ id, metadata, collectionName, quantity, revealable }: Props) => {
  const preview = (
    <FilePreview
      url={metadata?.image_resized}
      type="img"
      aspectRatio
      style={{ width: "100%", maxHeight: "284px", objectFit: "cover" }}
    />
  );

  const loadedBody = metadata && (
    <Link href={metadata.opensea_url} target="_blank" sx={{ flexGrow: 1 }}>
      <Tooltip title="See on OpenSea">
        <span>{preview}</span>
      </Tooltip>
    </Link>
  );

  const loadingBody = !metadata && (
    <Tooltip title="Your NFT files are not available yet. Please refresh this page in a few minutes.">
      <span>{preview}</span>
    </Tooltip>
  );

  const nftEl = (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        width: "284px",
        borderRadius: 1,
        overflow: "hidden",
        border: "1px solid rgba(145, 158, 171, 0.24)",
        backgroundColor: "background.paper",
      }}
    >
      {loadedBody || loadingBody}
      <Box p={2}>
        <Grid container>
          <Grid item xs={6}>
            <Typography color="text.primary" fontWeight={700} noWrap>
              {metadata ? metadata.name : "Loading..."}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {collectionName}
            </Typography>
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="flex-end">
            {revealable && <RevealButton />}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
  if (quantity) {
    return (
      <Badge badgeContent={`x${quantity}`} color="secondary">
        {nftEl}
      </Badge>
    );
  } else {
    return nftEl;
  }
};

export default NFT;
