import { Stack, Typography } from '@mui/material'
import { useCurrency } from '@inkerlabs/react-sdk/'

interface Props {
  price?: number
  totalSupply?: number
  amountMinted?: number
  salesShare?: number
  maxPerTx?: number
}

const SalesDetails = ({
  salesShare,
  price,
  totalSupply,
  amountMinted,
  maxPerTx,
}: Props) => {
  const currency = useCurrency()
  if ([price, totalSupply, amountMinted].indexOf(undefined) > -1) {
    return null
  }

  return (
    <Stack direction="row" alignItems="center" spacing={6}>
      <Stack spacing={1}>
        <Typography fontSize="0.75rem" color="text.secondary">
          PRICE
        </Typography>
        <Typography fontSize="1.2rem" fontWeight={700} color="text.primary">
          {price} {currency}
        </Typography>
      </Stack>
      <Stack spacing={1}>
        <Typography fontSize="0.75rem" color="text.secondary">
          QUANTITY LEFT
        </Typography>
        <Typography fontSize="1.2rem" fontWeight={700} color="text.primary">
          {totalSupply! - amountMinted!}/{totalSupply}
        </Typography>
      </Stack>
      {salesShare !== undefined && (
        <Stack spacing={1}>
          <Typography fontSize="0.75rem" color="text.secondary">
            SALES SHARE
          </Typography>
          <Typography fontSize="1.2rem" fontWeight={700} color="text.primary">
            {salesShare}
          </Typography>
        </Stack>
      )}
      {salesShare === undefined && maxPerTx && (
        <Stack spacing={1}>
          <Typography fontSize="0.75rem" color="text.secondary">
            MAX PER TRANSACTION
          </Typography>
          <Typography fontSize="1.2rem" fontWeight={700} color="text.primary">
            {maxPerTx}
          </Typography>
        </Stack>
      )}
    </Stack>
  )
}

export default SalesDetails
