import { Alert } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from "@mui/material/CircularProgress";

interface Props extends CircularProgressProps {
  message?: string;
}
function Spinner({ message, ...progressProps }: Props) {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "0",
        left: "0",
        bottom: "0",
        right: "0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box position="relative">
        <CircularProgress
          variant="determinate"
          sx={{
            color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
          }}
          size={40}
          thickness={4}
          {...progressProps}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: (theme) =>
            "#1a90ff",
            animationDuration: "550ms",
            position: "absolute",
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          }}
          size={40}
          thickness={4}
          {...progressProps}
        />
      </Box>
      {message && (
        <Alert sx={{mt: 2}} severity="warning" variant="filled">
          {message}
        </Alert>
      )}
    </Box>
  );
}

export default Spinner;
