import Store from './page/Store'
import { Routes, Route } from 'react-router-dom'

function App() {
  return (
    <Routes>
      <Route path="/:slug/*" element={<Store />} />
    </Routes>
  )
}

export default App
