import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";
import toast from "react-hot-toast";
import useReveal from "@inkerlabs/react-sdk/build/redeemAndReveal/useReveal";
import { sanitizeErrorMessage } from "../helpers";

interface Props {}

const RevealButton = ({}: Props) => {
  const reveal = useReveal();
  const [loading, setLoading] = useState(false);

  const _reveal = async () => {
    setLoading(true);
    await reveal();
  };

  return (
    <LoadingButton
      onClick={() =>
        toast.promise(
          _reveal(),
          {
            loading: "Minting...",
            success: (data) => {
              setLoading(false);
              return "NFT revealed!";
            },
            error: (err) => {
              setLoading(false);
              return `Failed to reveal NFT: ${sanitizeErrorMessage(err)}`;
            },
          }
        )
      }
      size="small"
      fullWidth
      disabled={loading}
      loading={loading}
      variant="outlined"
    >
      Open
    </LoadingButton>
  );
};

export default RevealButton;
