import { Button, Alert, Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { WhiteListTicket } from "@inkerlabs/react-sdk";
import ConnectWallet from "@inkerlabs/react-sdk/build/common/Wallet/ConnectWallet";
import { useAccount } from "@inkerlabs/react-sdk";
import { useState } from "react";
import QtyPicker from "./QuantityPicker";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { sanitizeErrorMessage } from "../helpers";

interface Props {
  supplyLeft: number;
  publicSales: boolean;
  privateSales: boolean;
  maxPerTx: number;
  price: number;
  wlTickets?: WhiteListTicket[];
  buyPublic: (qty: number) => Promise<boolean>;
  buyPrivate: (qty: number) => Promise<boolean>;
}

const MintButton = ({
  supplyLeft,
  publicSales,
  privateSales,
  maxPerTx,
  price,
  wlTickets,
  buyPublic,
  buyPrivate,
}: Props) => {
  const { address } = useAccount();
  const [loading, setLoading] = useState(false);
  const [qty, setQty] = useState(1);
  const navigate = useNavigate();
  const params = useParams();
  const isConnected = !!address;

  const _buyPrivate = async () => {
    setLoading(true);
    await buyPrivate(qty);
    navigate(`/${params.slug}/nfts`);
  };

  const _buyPublic = async () => {
    setLoading(true);
    await buyPublic(qty);
    navigate(`/${params.slug}/nfts`);
  };

  if (supplyLeft === 0) {
    return (
      <Button disabled variant="outlined">
        Sold out
      </Button>
    );
  }

  if (!publicSales && !privateSales) {
    return (
      <Button disabled variant="outlined">
        Sales are not opened yet
      </Button>
    );
  }

  if (!isConnected) {
    return <ConnectWallet />;
  }

  if (publicSales) {
    return (
      <Stack spacing={1}>
        <QtyPicker
          price={price}
          qty={qty}
          onChange={(_qty) => setQty(_qty)}
          max={Math.min(supplyLeft, maxPerTx)}
        />
        <LoadingButton
          fullWidth
          disabled={loading}
          loading={loading}
          onClick={() => {
            toast.promise(
              _buyPublic(),
              {
                loading: "Minting...",
                success: (data) => {
                  setLoading(false);
                  return "Mint successful!";
                },
                error: (err) => {
                  setLoading(false);
                  return `Failed to mint: ${sanitizeErrorMessage(err)}`;
                },
              }
            );
          }}
          variant="outlined"
        >
          Mint
        </LoadingButton>
      </Stack>
    );
  }

  if (privateSales) {
    if (isConnected && wlTickets === undefined) {
      return (
        <LoadingButton fullWidth disabled loading variant="outlined">
          Loading
        </LoadingButton>
      );
    } else if (wlTickets!.length === 0) {
      return (
        <Alert variant="outlined" severity="warning">
          It seems your wallet address is not in the allow list.
        </Alert>
      );
    } else {
      return (
        <Stack spacing={1}>
          <QtyPicker
            price={price}
            qty={qty}
            onChange={(_qty) => setQty(_qty)}
            max={Math.min(
              supplyLeft,
              Math.min(maxPerTx, wlTickets?.length || 0)
            )}
          />
          <LoadingButton
            fullWidth
            disabled={loading}
            loading={loading}
            onClick={() => {
              toast.promise(
                _buyPrivate(),
                {
                  loading: "Minting...",
                  success: (data) => {
                    setLoading(false);
                    return "Mint successful!";
                  },
                  error: (err) => {
                    setLoading(false);
                    return `Failed to mint: ${sanitizeErrorMessage(err)}`;
                  },
                }
              );
            }}
            variant="outlined"
          >
            Mint
          </LoadingButton>
        </Stack>
      );
    }
  }

  return null;
};

export default MintButton;
