import { Stack, Typography } from "@mui/material";
import { useContext } from "react";
import { StoreContext } from "../page/Store";
import { useParams, Link } from "react-router-dom";

interface Props {}

const Brand = ({}: Props) => {
  const { store } = useContext(StoreContext);
  const params = useParams();

  return (
    <Link style={{ textDecoration: "none" }} to={`/${params.slug}`}>
      <Stack direction="row" spacing={2} alignItems="center">
        <img src={store.logoUrl} alt="Brand logo" width="70" />
        <Typography
          sx={{ textTransform: "capitalize" }}
          fontSize="1.2rem"
          color="text.primary"
        >
          {store.name}
        </Typography>
      </Stack>
    </Link>
  );
};

export default Brand;
