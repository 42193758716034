import { Box, Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import Description from "../../../components/Description";
import Products from "./Products";
import { ContractMetadata, NFTsMetadata } from "@inkerlabs/sdk";
import { PassContractData, useConfig } from "@inkerlabs/react-sdk/build/pass";
import { WhiteListTicket } from "@inkerlabs/react-sdk";
import { useState } from "react";
import Faucet from "../../../components/Faucet";

interface Props {
  data: PassContractData;
  metadata: ContractMetadata;
  assetUrisByIds: {
    [key: string]: string;
  };
  getAssetsMetadata: (urisByIds: {
    [key: string]: string;
  }) => Promise<NFTsMetadata>;
  wlTickets: WhiteListTicket[] | undefined;
}

function Store({
  data,
  metadata,
  assetUrisByIds,
  wlTickets,
  getAssetsMetadata,
}: Props) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  const config = useConfig();
  const [image, setImage] = useState<string | undefined>(metadata.image_resized);

  return (
    <>
      <Box px={6} my={2}>
        <Faucet chain={config.chain} />
      </Box>
      <Grid container>
        <Grid item xs={12} md={6} p={6} order={isSmall ? 2 : 1}>
          <Stack spacing={6}>
            <a href={image} target="_blank">
              <Box
                sx={{
                  width: "100%",
                  backgroundSize: "cover",
                  borderRadius: "28px",
                  backgroundImage: `url(${image})`,
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                  height: "0px",
                  paddingBottom: "100%",
                }}
              />
            </a>
            <Description>{metadata.description}</Description>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} p={6} order={isSmall ? 1 : 2}>
          <Products
            onChange={(active) => {
              setImage(undefined);
              const assetId = Object.keys(assetUrisByIds)[active];
              if (assetId) {
                getAssetsMetadata({ [assetId]: assetUrisByIds[assetId] }).then(
                  (metadata) =>
                    setImage(metadata[Object.keys(metadata)[0]].image_resized)
                );
              }
            }}
            getAssetsMetadata={getAssetsMetadata}
            assetUrisByIds={assetUrisByIds}
            data={data}
            wlTickets={wlTickets}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default Store;
