import { Box } from "@mui/material";
import TopBar from "../../../components/TopBar";
import Spinner from "../../../components/Spinner";
import {
  useContractMetadata,
  useContractData,
  useWhiteListTickets,
  useAssets,
  useBalances,
  useConfig,
} from "@inkerlabs/react-sdk/build/pass";
import NftContainer from "./NftContainer";
import StorePage from "./Store";
import { Route, Routes } from "react-router-dom";
import Footer from "../../../components/Footer";

function PassStore() {
  const config = useConfig();
  const [metadata, metadataError] = useContractMetadata();
  const [data, dataError] = useContractData();
  const [wlTickets, wlError] = useWhiteListTickets(!!data?.privateSales);
  const { assetUris, getAssetsMetadata, error: assetUrisError } = useAssets();
  const [balances, balancesError] = useBalances();

  if ([assetUris, metadata, data].indexOf(undefined) > -1) {
    return <Spinner />;
  }

  if ([assetUris, metadata, data].indexOf(undefined) > -1) {
    const message =
      assetUrisError || metadataError || dataError
        ? "Please check for network switch request."
        : undefined;
    return <Spinner message={message} />;
  }

  const nftCount = balances
    ? Object.values(balances).reduce((prev, curr) => prev + curr, 0)
    : 0;

  return (
    <>
      <TopBar nftCount={nftCount} chain={config.chain} />
      <Box maxWidth="1000px" margin="auto">
        <Routes>
          <Route
            path="/"
            element={
              <StorePage
                getAssetsMetadata={getAssetsMetadata}
                data={data!}
                metadata={metadata!}
                assetUrisByIds={assetUris!}
                wlTickets={wlTickets}
              />
            }
          />
          <Route
            path="/nfts"
            element={
              <NftContainer
                assetUrisByIds={assetUris!}
                getAssetsMetadata={getAssetsMetadata}
                balances={balances}
                collectionName={metadata?.name!}
              />
            }
          />
        </Routes>
      </Box>
      <Footer />
    </>
  );
}

export default PassStore;
