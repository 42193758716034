import { Stack, Typography, Grid, Alert, Pagination } from "@mui/material";
import { NFTsMetadata } from "@inkerlabs/sdk/";
import NFT from "./Nft";
import { AssetMetadata } from "@inkerlabs/react-sdk";
import { chunk } from "lodash";
import { useEffect, useState } from "react";

interface Props {
  collectionName: string;
  tokens?: string[];
  getTokensMetadataLazy: (tokens: string[], cb: (index: (string|number), metadata: AssetMetadata) => any) => any;
  redeemableAsset?: AssetMetadata;
  redeemableBalance?: number;
  redeemAllowed?: boolean;
}

let buffer: NFTsMetadata = {}

const NftContainer = ({
  getTokensMetadataLazy,
  tokens,
  collectionName,
  redeemableAsset,
  redeemableBalance,
  redeemAllowed,
}: Props) => {
  const PAGE_SIZE = 9;
  const paginatedTokens = chunk(tokens, PAGE_SIZE);
  const [page, setPage] = useState(0);
  const [nftsMetadata, setNftsMetadata] = useState<NFTsMetadata>({});

  useEffect(() => {
    const pageTokens = paginatedTokens[page];
    getTokensMetadataLazy(pageTokens, (index, metadata) => {
      buffer = {...buffer, ...{[index]: metadata}}
      setNftsMetadata(buffer)
    })
    // getTokensMetadata(pageTokens)
    // .then((nftsMetadata) => setNftsMetadata(nftsMetadata))
    // .catch((err) => setNftsMetadata({}));
    // getTokensMetadata(pageTokens)
    //   .then((nftsMetadata) => setNftsMetadata(nftsMetadata))
    //   .catch((err) => setNftsMetadata({}));
  }, [page, tokens]);

  if (tokens?.length === 0 && !redeemableBalance) {
    return (
      <Alert variant="filled" severity="info">
        No NFTs found. Try to connect your wallet.
      </Alert>
    );
  }

  return (
    <Stack spacing={4} p={6} alignItems="center">
      <Stack
        direction="row"
        position="relative"
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          align="center"
          fontWeight={700}
          fontSize="2rem"
          color="text.primary"
        >
          My NFTs
        </Typography>
      </Stack>
      <Grid container rowSpacing={2}>
        {!!redeemableAsset && !!redeemableBalance && page === 0 && (
          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            display="flex"
            justifyContent="center"
          >
            <NFT
              id="0"
              metadata={redeemableAsset}
              collectionName={collectionName}
              quantity={redeemableBalance}
              revealable={redeemAllowed}
            />
          </Grid>
        )}
        {(paginatedTokens[page] || []).map((id) => {
          return (
            <Grid
              item
              key={id}
              xs={12}
              sm={6}
              lg={4}
              display="flex"
              justifyContent="center"
            >
              <NFT
                id={id}
                metadata={(nftsMetadata || {})[id]}
                collectionName={collectionName}
              />
            </Grid>
          );
        })}
      </Grid>
      <Pagination
        count={paginatedTokens.length}
        color="secondary"
        onChange={(_, page) => setPage(page - 1)}
      />
    </Stack>
  );
};

export default NftContainer;
