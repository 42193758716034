import { Box, Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import TopBar from "../../components/TopBar";
import Footer from "../../components/Footer";
import CollectionName from "../../components/CollectionName";
import SalesDetails from "../../components/SalesDetails";
import MintButton from "../../components/MintButton";
import NftContainer from "../../components/NftContainer";
import Description from "../../components/Description";
import Spinner from "../../components/Spinner";
import {
  useContractData,
  useWhiteListTickets,
  useBuyPrivate,
  useBuyPublic,
  useNFTs,
  useRedeemableAsset,
  useRedeemableBalance,
  useConfig,
} from "@inkerlabs/react-sdk/build/redeemAndReveal";
import { Route, Routes } from "react-router-dom";
import Faucet from "../../components/Faucet";

function RedeemAndRevealStore() {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const config = useConfig();
  const [data, dataError] = useContractData();
  const [wlTickets, wlError] = useWhiteListTickets(!!data?.privateSales);
  const [assetMetadata, assetMetadataError] = useRedeemableAsset();
  const [redeemableBalance, redeemableBalanceError] = useRedeemableBalance();
  const buyPublic = useBuyPublic();
  const buyPrivate = useBuyPrivate();
  const { tokens, getTokensMetadataLazy, error: nftError } = useNFTs();

  if ([assetMetadata, data].indexOf(undefined) > -1) {
    const message =
      assetMetadataError || dataError
        ? "Please check for network switch request."
        : undefined;
    return <Spinner message={message} />;
  }

  const supplyLeft = data!.maxSupply - data!.amountMinted;
  const price = data!.privateSales
    ? data!.earlyAccessTokenPrice
    : data!.tokenPrice;

  const nftCount = (tokens?.length || 0) + (redeemableBalance || 0);

  const store = (
    <>
      <Box px={6} my={2}>
        <Faucet chain={config.chain} />
      </Box>
      <Grid container>
        <Grid item xs={12} md={6} p={6} order={isSmall ? 2 : 1}>
          <Stack spacing={6}>
            <a href={assetMetadata!.image_resized} target="_blank">
              <Box
                sx={{
                  width: "100%",
                  backgroundSize: "cover",
                  borderRadius: "28px",
                  backgroundImage: `url(${assetMetadata!.image_resized})`,
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                  height: "0px",
                  paddingBottom: "100%",
                }}
              />
            </a>
            <Description>{assetMetadata?.description}</Description>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} p={6} order={isSmall ? 1 : 2}>
          <Stack spacing={3}>
            <CollectionName
              name={assetMetadata!.name}
              chain={config.chain}
              contractAddress={config.redeemableAddress}
              privateSales={data?.privateSales}
            />
            <SalesDetails
              price={price}
              maxPerTx={data!.maxPerTx}
              amountMinted={data!.amountMinted}
              totalSupply={data!.maxSupply}
              salesShare={data?.privateSales ? wlTickets?.length : undefined}
            />
            <MintButton
              price={price}
              supplyLeft={supplyLeft}
              maxPerTx={data!.maxPerTx}
              privateSales={data!.privateSales}
              publicSales={data!.publicSales}
              wlTickets={wlTickets}
              buyPublic={buyPublic}
              buyPrivate={buyPrivate}
            />
          </Stack>
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      <TopBar nftCount={nftCount} chain={config.chain} />
      <Box maxWidth="1000px" margin="auto">
        <Routes>
          <Route path="/" element={store} />
          <Route
            path="/nfts"
            element={
              <NftContainer
                tokens={tokens}
                getTokensMetadataLazy={getTokensMetadataLazy}
                collectionName={assetMetadata?.name!}
                redeemableAsset={assetMetadata}
                redeemableBalance={redeemableBalance}
                redeemAllowed={data?.migrationStarted}
              />
            }
          />
        </Routes>
      </Box>
      <Footer />
    </>
  );
}

export default RedeemAndRevealStore;
