import { Stack, Typography, Grid, Alert, Pagination } from "@mui/material";
import { NFTsMetadata } from "@inkerlabs/sdk/";
import NFT from "../../../components/Nft";
import { chunk } from "lodash";
import { useEffect, useState } from "react";

interface Props {
  collectionName: string;
  assetUrisByIds: {
    [key: string]: string;
  };
  getAssetsMetadata: (assetUrisByIds: {
    [key: string]: string;
  }) => Promise<NFTsMetadata>;
  balances?: {
    [key: string]: number;
  };
}

const NftContainer = ({
  assetUrisByIds,
  getAssetsMetadata,
  collectionName,
  balances,
}: Props) => {
  const PAGE_SIZE = 10;
  const paginatedAssets = chunk(Object.keys(balances || {}), PAGE_SIZE);
  const [page, setPage] = useState(0);
  const [assetsMetadata, setAssetsMetadata] = useState<NFTsMetadata>({});

  useEffect(() => {
    const pageAssetIds = paginatedAssets[page];
    const partialAssetUrisByIds: { [key: string]: string } = {};
    pageAssetIds.forEach(
      (id) => (partialAssetUrisByIds[id] = assetUrisByIds[id])
    );
    getAssetsMetadata(partialAssetUrisByIds)
      .then((nftsMetadata) => setAssetsMetadata(nftsMetadata))
      .catch((err) => setAssetsMetadata({}));
  }, [page, balances]);

  const balanceSum =
    balances && Object.values(balances).reduce((prev, curr) => prev + curr, 0);

  if (
    !assetUrisByIds ||
    Object.keys(assetUrisByIds).length === 0 ||
    !balanceSum
  ) {
    return (
      <Alert variant="filled" severity="info">
        No NFTs found.
      </Alert>
    );
  }

  return (
    <Stack spacing={4} p={6} alignItems="center">
      <Stack
        direction="row"
        position="relative"
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          align="center"
          fontWeight={700}
          fontSize="2rem"
          color="text.primary"
        >
          My NFTs
        </Typography>
      </Stack>
      <Grid container rowSpacing={2}>
        {Object.entries(balances || {}).map(([id, quantity]) => {
          if (!quantity) {
            return null;
          }
          return (
            <Grid
              item
              key={id}
              xs={12}
              sm={6}
              lg={4}
              display="flex"
              justifyContent="center"
            >
              <NFT
                id={id}
                metadata={assetsMetadata[id]}
                collectionName={collectionName}
                quantity={quantity}
              />
            </Grid>
          );
        })}
      </Grid>
      <Pagination
        count={paginatedAssets.length}
        color="secondary"
        onChange={(_, page) => setPage(page - 1)}
      />
    </Stack>
  );
};

export default NftContainer;
