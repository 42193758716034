export function sanitizeErrorMessage(error: any) {
  if (error?.data?.message) {
    return error.data.message;
  } else if (error?.error?.message) {
    return error.error.message;
  } else if (error.message) {
    return error.message;
  } else {
    return error;
  }
}
