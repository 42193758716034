import * as React from "react";
import {
  MobileStepper,
  Button,
  useTheme,
  Stack,
  Alert,
  Skeleton,
  Grid,
} from "@mui/material";
import CollectionName from "../../../components/CollectionName";
import SalesDetails from "../../../components/SalesDetails";
import MintButton from "../../../components/MintButton";
import {
  useBuyPrivate,
  useBuyPublic,
  PassContractData,
  useConfig,
} from "@inkerlabs/react-sdk/build/pass";
import { NFTsMetadata } from "@inkerlabs/sdk/";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { AssetMetadata, WhiteListTicket } from "@inkerlabs/react-sdk";
import Description from "../../../components/Description";

interface Props {
  assetUrisByIds: {
    [key: string]: string;
  };
  getAssetsMetadata: (assetUrisByIds: {
    [key: string]: string;
  }) => Promise<NFTsMetadata>;
  wlTickets?: WhiteListTicket[];
  data: PassContractData;
  onChange?: (activeStep: number) => any;
}

function Products({
  assetUrisByIds,
  getAssetsMetadata,
  data,
  wlTickets,
  onChange,
}: Props) {
  const config = useConfig();
  const buyPublic = useBuyPublic();
  const buyPrivate = useBuyPrivate();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [assetId, setAssetId] = React.useState<string | undefined>();
  const [assetsMetadata, setAssetMetadata] = React.useState<
    AssetMetadata | undefined
  >();

  React.useEffect(() => {
    if (onChange) {
      onChange(activeStep);
    }
    setAssetId(undefined);
    setAssetMetadata(undefined);
    const assetId = Object.keys(assetUrisByIds)[activeStep];
    getAssetsMetadata({ [assetId]: assetUrisByIds[assetId] })
      .then((metadata) => {
        const _assetId = Object.keys(metadata)[0];
        setAssetId(_assetId);
        setAssetMetadata(metadata[_assetId]);
      })
      .catch((err) => {
        setAssetId(undefined);
        setAssetMetadata(undefined);
      });
  }, [activeStep]);

  const maxSteps = Object.keys(assetUrisByIds).length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  if (maxSteps === 0) {
    return (
      <Stack spacing={3}>
        <Alert severity="info">Store is empty.</Alert>
      </Stack>
    );
  }

  if (!assetId || !assetsMetadata) {
    return (
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12}>
          <Skeleton animation="wave" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton animation="wave" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton animation="wave" />
        </Grid>
      </Grid>
    );
  }

  const salesConfig = data.assetSaleConfigs[assetId];

  const supplyLeft = salesConfig.maxSupply - data.amountMinted[assetId];
  const price = data!.privateSales
    ? salesConfig.earlyAccessTokenPrice
    : salesConfig.tokenPrice;

  return (
    <Stack spacing={3}>
      <CollectionName
        chain={config.chain}
        name={assetsMetadata.name}
        contractAddress={config.contractAddress}
        privateSales={data.privateSales}
      />
      <SalesDetails
        price={price as unknown as number}
        amountMinted={data.amountMinted[assetId]}
        totalSupply={salesConfig.maxSupply}
        salesShare={data?.privateSales ? wlTickets?.length : undefined}
      />
      <Description>{assetsMetadata.description}</Description>
      <MintButton
        price={price as unknown as number}
        supplyLeft={supplyLeft}
        privateSales={data!.privateSales}
        publicSales={data!.publicSales}
        maxPerTx={salesConfig.maxPerTx}
        wlTickets={wlTickets}
        buyPublic={(qty: number) => buyPublic(assetId, qty)}
        buyPrivate={(qty: number) => buyPrivate(assetId, qty)}
      />
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Stack>
  );
}

export default Products;
