import React, { memo, useState, useRef } from "react";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

interface Props {
  aspectRatio?: boolean;
  url?: string;
  type?: "img" | "video";
  style?: React.CSSProperties;
}

function FilePreview({ url, type, style, aspectRatio }: Props) {
  const ref = useRef<any>();
  const [loaded, setLoaded] = useState(false);

  let src = url;
  let el: any;

  if (type === "img") {
    el = (
      <img
        hidden={!loaded}
        onLoad={(e) => {
          const success =
            (e.target as HTMLImageElement).naturalHeight +
              (e.target as HTMLImageElement).naturalWidth >
            0;
          if (success) {
            setLoaded(true);
          }
        }}
        style={{ width: "100%", maxHeight: "100%", ...style }}
        width="100%"
        src={src}
      />
    );
  } else if (type === "video") {
    el = (
      <video
        hidden={!loaded}
        src={src}
        onLoadedData={() => {
          setLoaded(true);
        }}
        style={{
          pointerEvents: "none",
          width: "100%",
          ...style,
        }}
        autoPlay
        muted
        loop
      />
    );
  }

  return (
    <Box display="flex" alignItems="center" height="100%" ref={ref}>
      {(!loaded || !url) && (
        <Skeleton
          variant="rectangular"
          style={{
            width: "100%",
            height: 0,
            paddingBottom: aspectRatio ? "100%" : 0,
            borderRadius: style?.borderRadius,
          }}
        />
      )}
      {el}
    </Box>
  );
}

export default memo(FilePreview);
