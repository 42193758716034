import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import NotFoundPage from "../404";
import SimultaneousRevealStore from "./SimultaneousRevealStore";
import PassStore from "./PassStore";
import RedeemAndRevealStore from "./RedeemAndRevealStore";
import Spinner from "../../components/Spinner";
import { Provider } from "@inkerlabs/react-sdk/";
import { UseCase } from "@inkerlabs/sdk";
import { Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { lightTheme, darkTheme } from "../../theme";

interface Props {}

interface IStore {
  config: any;
  useCase: UseCase;
  store: {
    logoUrl: string;
    name: string;
    darkMode?: boolean;
    discord?: string;
    instagram?: string;
    website?: string;
    twitter?: string;
  };
}

function renderStore(useCase: UseCase) {
  switch (useCase) {
    case UseCase.PASS:
      return <PassStore />;
    case UseCase.SR:
      return <SimultaneousRevealStore />;
    case UseCase.RR:
      return <RedeemAndRevealStore />;
  }
}

export const StoreContext = React.createContext<IStore>({} as any);

const getTheme = (storeData: IStore | undefined) => {
  return storeData?.store.darkMode ? darkTheme() : lightTheme();
};

const Store = ({}: Props) => {
  const { slug } = useParams();
  const [notFound, setNotFound] = useState(false);
  const [storeData, setStoreData] = useState<IStore | undefined>();
  const getStore = async function () {
    const response = await fetch(`https://getstore-ke5ch2pafa-ew.a.run.app`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        slug,
      }),
    });
    if (!response.ok) {
      setStoreData(undefined);
      setNotFound(true);
      return;
    }
    const storeData = await response.json();
    storeData.config.alchemyApiKey = "KyT3gP7XALK8dEugw1lcpz_X3EAbu_jN";
    setStoreData(storeData);
    setNotFound(false);
  };

  useEffect(() => {
    getStore();
  }, [slug]);

  useEffect(() => {
    const theme = getTheme(storeData);
    const root = document.querySelector("#root") as HTMLDivElement;
    root.style.background = theme.palette.background.default;
    document.documentElement.style.background =
      theme.palette.background.default;
  }, [storeData]);

  const loading = !notFound && !storeData;
  if (loading) {
    return <Spinner />;
  }

  if (notFound) {
    return <NotFoundPage />;
  }

  return (
    <ThemeProvider theme={getTheme(storeData)}>
      <Provider
        config={storeData!.config}
        useCase={storeData!.useCase}
        whiteLabel={{ logo: storeData!.store.logoUrl }}
      >
        <StoreContext.Provider value={storeData!}>
          <Box>{renderStore(storeData!.useCase)}</Box>
        </StoreContext.Provider>
      </Provider>
    </ThemeProvider>
  );
};

export default Store;
