import { Typography, Stack } from '@mui/material'
import React, { useState } from 'react'

interface Props {}

const Description = ({ children }: React.PropsWithChildren<Props>) => {
  const text = children
  const [isReadMore, setIsReadMore] = useState(true)
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  }

  if (!text) {
    return null
  }

  return (
    <Stack spacing={1}>
      <Typography
        color="text.primary"
        fontWeight={500}
        sx={{
          lineHeight: '24px',
          textAlign: 'justify',
          whiteSpace: 'pre-line',
          WebkitLineClamp: isReadMore ? 10 : undefined,
          overflow: isReadMore ? 'hidden' : 'auto',
          WebkitBoxOrient: 'vertical',
          display: '-webkit-box',
        }}
      >
        {text}
      </Typography>
      <Typography
        sx={{ cursor: 'pointer' }}
        color="text.primary"
        fontWeight={700}
        onClick={toggleReadMore}
      >
        {isReadMore ? 'More' : 'Less'}
      </Typography>
    </Stack>
  )
}

export default Description
