import { useParams } from 'react-router-dom'
import { Button, Badge } from '@mui/material'
import { AutoAwesome } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { useAccount } from '@inkerlabs/react-sdk'

interface Props {
  nftCount: number;
}

const Nav = ({ nftCount }: Props) => {
  const { address } = useAccount()
  const params = useParams()
  const isNftPage = params['*'] === 'nfts'

  if (isNftPage) {
    return (
      <Button
        component={Link}
        to={`/${params.slug}`}
        variant="contained"
        disableElevation
        startIcon={<AutoAwesome />}
      >
        Mint
      </Button>
    );
  } else if (nftCount && address) {
    return (
      <Badge badgeContent={nftCount} color="secondary">
        <Button
          component={Link}
          to={`/${params.slug}/nfts`}
          variant="contained"
          disableElevation
        >
          My NFTs
        </Button>
      </Badge>
    );
  } else {
    return null;
  }
};

export default Nav;
