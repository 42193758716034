import { Typography, Stack, Chip } from "@mui/material";

interface Props {}

const Footer = ({}: Props) => {
  return (
    <Chip
      sx={{
        position: "fixed",
        bottom: "10px",
        right: "10px",
      }}
      label={
        <Stack direction="row" alignItems="center" spacing={1}>
          <img src="/logo.svg" width={15} />
          <span>Powered by Inker Labs</span>
        </Stack>
      }
      onClick={() => window.open("https://inkerlabs.com", "_blank")}
    />
  );
};

export default Footer;
