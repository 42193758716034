import { Typography, IconButton, Stack } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import * as yup from "yup";
import { useCurrency } from "@inkerlabs/react-sdk";

interface Props {
  qty: number;
  max: number;
  price: number;
  onChange: (qty: number) => any;
}

const QuantityPicker = ({ price, qty, max, onChange }: Props) => {
  const currency = useCurrency();
  const schema = yup.object({
    quantity: yup.number().min(1).max(max).required(),
  });

  const validate = (_qty: number) => schema.isValidSync({ quantity: _qty });

  const updateQty = (_qty: number) => {
    if (validate(_qty)) {
      onChange(_qty);
    }
  };

  return (
    <Stack direction="row" alignItems="center">
      <IconButton
        size="large"
        onClick={() => updateQty(qty - 1)}
        disabled={!validate(qty - 1)}
      >
        <Remove />
      </IconButton>
      <Typography
        mx={2}
        fontSize="2.5rem"
        fontWeight={700}
        color="text.primary"
      >
        {qty}
      </Typography>
      <IconButton
        size="large"
        onClick={() => updateQty(qty + 1)}
        disabled={!validate(qty + 1)}
      >
        <Add />
      </IconButton>
      <Typography
        mx={2}
        fontSize="1.5rem"
        fontWeight={700}
        color="text.secondary"
      >
        {(qty * price).toFixed(3)} {currency}
      </Typography>
    </Stack>
  );
};

export default QuantityPicker;
