import { Grid } from "@mui/material";
import { Chain } from "@inkerlabs/sdk";
import Nav from "./Nav";
import Wallet from "@inkerlabs/react-sdk/build/common/Wallet";
import Brand from "./Brand";

interface Props {
  nftCount: number;
  chain: Chain;
}

const TopBar = ({ nftCount }: Props) => {

  return (
    <Grid
      margin="auto"
      container
      minHeight="100px"
      maxWidth="1450px"
      alignItems="center"
      p={2}
    >
      <Grid xs={12} sm="auto" item mr="auto">
        <Brand />
      </Grid>
      <Grid xs={12} sm="auto" item ml="auto">
        <Grid container spacing={1} alignItems="center" justifyContent="end">
          <Grid item>
            <Nav nftCount={nftCount} />
          </Grid>
          <Grid item>
            <Wallet />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TopBar;
