import { Link } from '@mui/material'
import Alert from '@mui/material/Alert'
import { Chain } from '@inkerlabs/sdk'
import { availableChains } from '@inkerlabs/react-sdk'

interface Props {
  chain: Chain
}

const faucets = {
  [Chain.MATICMUM]: 'https://faucet.polygon.technology/',
  [Chain.RINKEBY]: 'https://faucets.chain.link/',
  [Chain.GOERLI]: 'https://goerlifaucet.com/',
  [Chain.HOMESTEAD]: '',
  [Chain.MATIC]: '',
}

export default function Faucet({ chain }: Props) {
  const network = availableChains.find((c) => c.network === chain)

  if (!network || !network.testnet) {
    return null
  }

  return (
    <Alert variant="filled" severity="info">
      This collection is deployed on the {network.name} test network. You can
      get {network.nativeCurrency?.name} for free by using{' '}
      <Link color="inherit" href={faucets[chain]} target="_blank">
        this faucet
      </Link>
      .
    </Alert>
  )
}
