import { Typography, Stack } from '@mui/material'
import { Chain } from '@inkerlabs/sdk'
import Socials from './Socials'
import { Box } from '@mui/system'

interface Props {
  name: string
  chain: Chain
  contractAddress: string
  privateSales?: boolean
}

const CollectionName = ({ name, chain, contractAddress }: Props) => {
  return (
    <Stack direction="column" spacing={2}>
      <Box ml="auto">
        <Socials chain={chain} contractAddress={contractAddress} />
      </Box>

      <Typography fontSize="2.5rem" fontWeight={700} color="text.primary">
        {name}
      </Typography>
    </Stack>
  )
}

export default CollectionName
